import { Component } from 'solid-js'
import { LoadingProps } from '~/components/loading/loading.interfaces'
import styles from '~/components/loading/loading.module.scss'
import LoadingIcon from '~/assets/icons/loading.svg'
import { translations } from '~/translations'

const Loading: Component<LoadingProps> = ({ message }) => {
  return (
    <div class={styles.container}>
      <LoadingIcon class={styles.icon} />
      <div class={styles.text}>{message ?? translations().loading.default}</div>
    </div>
  )
}

export default Loading